import React from "react";
import Navbar from "../../Shared/Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Topic = () => {
  const data = [
    {
      name: "strategy",
      bg: "#CCB6FA",
    },
    {
      name: "HR",
      bg: "#FFFCF9",
    },
    {
      name: "social media",
      bg: "#FFFCF9",
    },
    {
      name: "operations",
      bg: "#98BBFF",
    },
    {
      name: "compliance",
      bg: "#FFFCF9",
    },
    {
      name: "marketing",
      bg: "#FF8888",
    },
    {
      name: "IT",
      bg: "#FFFCF9",
    },
    {
      name: "sustaainability",
      bg: "#FFFCF9",
    },
    {
      name: "finance",
      bg: "#FFFCF9",
    },
    {
      name: "sales",
      bg: "#91EDC1",
    },
  ];

  return (
    <div className="  min-h-[100vh] pt-[100px] sm:pt-[200px] ">
      <ToastContainer />

      <div className="  fontClass text-center text-[53px] md:text-[119.75px] leading-[62px] md:leading-[140.34px] text-[#313131] font-black ">
        Any{" "}
        <span className="font-extralight  ml-[-10px] md:ml-[-30px] ">
          Topic
        </span>
      </div>
      <div className=" mt-[80px]  flex  flex-col-reverse lg:flex-row  justify-center gap-[100px]  items-center">
        <div className="flex gap-[10px] justify-center items-center flex-wrap max-w-[582px] px-8">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                style={{ backgroundColor: item.bg }}
                className="border-[#313131] text-[#313131] fontClass text-[19.32px] md:text-[22.51px] leading-[26.37px] md:leading-[26.37px] text-center border-[1px] rounded-[12.41px] px-[25px] py-[10px]"
              >
                {item.name}
              </div>
            );
          })}
        </div>
        <div>
          <div className="font-semibold fontClass  text-center md:text-start text-[24px]   md:text-[36px] leading-[28px] md:leading-[42.19px] text-[#313131]">
            Step 1
          </div>
          <div className="max-w-[230px] mt-[10px] mx-auto fontClass text-[15px] leading-[17.58px] block md:hidden text-center ">
            Find an expert that suits your needs. Check out their tags and bio
            to see if they are the right person for your context.
          </div>
          <div className="mt-[20px]  max-w-[398px] text-start text-[22.51px] leading-[26.37px] hidden md:block ">
            Find an expert that suits your needs. Check out their tags and bio
            to see if they are the right person for your context.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topic;
