import React, { useState } from "react";
import Navbar from "../Shared/Navbar/Navbar";
import { doc, addDoc, setDoc, collection } from "firebase/firestore";
import { db } from '../../config/firebase.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Timestamp } from "@firebase/firestore";
import AppStoreButton from "../Shared/Buttons/iOS";
const AnyExpert = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    if (email) {

      await setDoc(doc(db, "waitlist", email), {
        email: email,
        date: Timestamp.fromDate(new Date()),
      });

      await addDoc(collection(db, "mail", ""), {
        to: [email],
        template: {
          name: 'waitlist_v1',
        },
      });

      showToastMessage();

      document.getElementById("contact-form-expert-waitlist").reset();
    }
  }

  const showToastMessage = () => {
    toast("Thank you for your interest!", {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: 'colored',
      style: { background: '#FFFCF9' },
    });
  };

  return (
    <div className="  min-h-[70vh] sm:min-h-[90vh]">
      <ToastContainer />
      <div className=" mt-[100px] fontClass text-center text-[56px] md:text-[183.75px]  leading-[65px] md:leading-[214.34px] text-[#F0ECE8] font-black ">
        Any{" "}
        <span className="font-extralight ml-[-10px] md:ml-[-50px]">Expert</span>
      </div>

      <div className=" mt-[50px]  text-center flex justify-center items-center fontClass text-[16px] md:text-[22.51px]  leading-[18.75px] md:leading-[26.37px]  text-[#F0ECE8]">
        Download our app today
        <br />
        and share your knowledge and experience with the world.
      </div>
      <div className="mt-[20px] flex flex-col   md:flex-row gap-[10px] justify-center items-center  pr-[20px] md:pr-[0px] ">
            <AppStoreButton image_url="/images/appstore_button_black.svg" size={{ width: 200, height: 100 }} />
      </div>

      <div className="  mt-[50px] text-[#F0ECE8] text-center latClass font-medium text-[17.05px] leading-[20.46px] pr-[20px] md:pr-[0px] ">
        <span>Questions?</span>{" "}
        <span
          className="border-b-[1px] border-b-[white] cursor-pointer"
          onClick={scrollToBottom}
        >
          Contact us
        </span>
      </div>
    </div>
  );
};

export default AnyExpert;
