import React, { useState } from "react";

const AIBuilt = () => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredCardIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredCardIndex(null);
  };

  const card = [
    {
      title: "Talk to customers while you sleep",
      text: "Create a chat bot that can talk to your clients for you. Even while you are sleeping.",
    },
    {
      title: "Monetize your corpus of information",
      text: "Train your AI with your specific expertise. It’ll also continue to learn from your video sessions.",
    },
    {
      title: "Partner with us to make your bot the best",
      text: "Work with use to ensure your bot is the highest quality before making it available to book.",
    },
  ];

  return (
    <div className="min-h-[100vh] sm:pt-[100px]  sm:pb-[150px] ">
      <div className="fontcClass text-[53px] md:text-[119.75px] text-center font-extralight text-[#E7E3DC] leading-[62px] md:leading-[140.34px]">
        <span className="font-black text-[#CCB6FA]">AI</span>Built
      </div>

      <div className="mt-[50px] flex gap-[20px] justify-center flex-wrap items-center px-[20px]">
        {card.map((item, index) => {
          const isHovered = hoveredCardIndex === index;
          return (
            <div
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              className={`cursor-pointer ${
                index === 1
                  ? "mt-[20px] md:mt-[50px]"
                  : index === 2
                  ? "mt-[20px] md:mt-[100px]"
                  : ""
              } rounded-[30px] bg-[#474747] py-[20px] pl-[20px] pr-[30px] h-[236px] md:h-[306px] flex flex-col justify-between items-center ${
                isHovered
                  ? "border-r-[10px] border-r-[#CCB6FA] border-b-[10px] border-b-[#CCB6FA] border-t-[#CCB6FA] border-l-[#CCB6FA] border-l-[2px] border-t-[2px]"
                  : "border-r-[1px] border-r-[#FFFCF9] border-b-[2px] border-b-[#FFFCF9] border-l-[2px] border-l-[#FFFCF9] border-t-[1px] border-t-[#FFFCF9]"
              }`}
            >
              <div className="font-bold fontClass text-[25px] md:text-[29px] leading-[29.3px] md:leading-[33.98px] text-[#FFFCF9] max-w-[236px]">
                {item.title}
              </div>
              <div className="fontClass text-[16px] leading-[18.75px] text-[#FFFCF9] max-w-[236px]">
                {item.text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AIBuilt;
