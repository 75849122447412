import React, { useState } from "react";

const Benefits = () => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredCardIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredCardIndex(null);
  };

  const card = [
    {
      titel: "Market your expertise",
      text: "Increase your visibility by being listed on the AnyExpert platform.",
    },
    {
      titel: "Video Calls     ",
      text: "Easily conduct your video sessions right on the app.",
    },
    {
      titel: "Scheduling",
      text: "Just set your available slots and users will be able to book them.",
    },
    {
      titel: "Profile Link",
      text: "Show off your profile with pride. Add your link to your website or share it for people to book you.",
    },
    {
      titel: "Passive Income",
      text: "Share your expertise to earn extra income. Your AI can help you generate passive income too.",
    },
    {
      titel: "Flexibility",
      text: "AnyExpert provides the flexibility set your own hours and rates.",
    },
  ];

  return (
    <div className="min-h-[100vh] mt-[100px]">
      <div className="fontcClass text-[53px] md:text-[119.75px] text-center font-extralight text-[#E7E3DC] leading-[62px] md:leading-[140.34px]">
        <span className="font-black text-[#F9A880]">More</span>Benefits
      </div>

      <div className="mt-[50px] flex gap-[20px] flex-wrap justify-center items-center max-w-[1050px] mx-auto">
        {card.map((item, index) => {
          const isHovered = hoveredCardIndex === index;
          return (
            <div
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              className={`cursor-pointer ${
                index === 1 || index === 3 || index === 5
                  ? "ml-[50px] sm:ml-[0px]"
                  : ""
              } rounded-[30px] bg-[#474747] py-[20px] pl-[20px] pr-[30px] h-[197px] flex flex-col justify-between items-center ${
                isHovered
                  ? "border-r-[10px] border-r-[#F9A880] border-b-[10px] border-b-[#F9A880] border-t-[#F9A880] border-t-[2px] border-l-[#F9A880] border-l-[2px]"
                  : "border-r-[1px] border-r-[#FFFCF9] border-b-[1px] border-b-[#FFFCF9] border-l-[1px] border-l-[#FFFCF9] border-t-[1px] border-t-[#FFFCF9]"
              }`}
            >
              <div className="font-bold text-left w-full flex justify-start items-start fontClass text-[24px] leading-[28px] text-[#FFFCF9] min-w-[100px] max-w-[220px]">
                {item.titel}
              </div>
              <div className="fontClass text-[16px] leading-[18.75px] text-[#FFFCF9] max-w-[220px]">
                {item.text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Benefits;
