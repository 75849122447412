import React from 'react';
import PropTypes from 'prop-types';

const AppStoreButton = ({ link, className, image_url, size }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={`app-store-button ${className || ''}`}
    >
      {/* The official “Download on the App Store” badge */}
      <img
        src={image_url}
        alt="Download on the App Store"
        className="app-store-badge"
        style={{ width: size.width, height: size.height }}
      />
    </a>
  );
};

AppStoreButton.propTypes = {
  /** The URL of your app on the Apple App Store */
  link: PropTypes.string,
  /** Optional custom class for styling overrides */
  className: PropTypes.string,
  /** The URL of your app on the Apple App Store */
  image_url: PropTypes.string.isRequired,
  /** Size object containing width and height for the button */
  size: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

AppStoreButton.defaultProps = {
  link: 'https://apps.apple.com/us/app/anyexpert/id6473666211',
  className: '',
  image_url: '/images/appstore_button_black.svg',
  size: {
    width: 160,
    height: 80,
  },
};

export default AppStoreButton;